import moment from 'moment-timezone'

import keys from '../config/keys'
import store from '../redux/store'
import { NoDataUI } from './noData'

export const getAppTimezone = ({ display } = {}) => {
	const isEvent = window.location.href.includes(`/${keys.MODULES.events}/`)
	const info = store.getState().currentData?.viewAndEdit || {}

	const key = `microsite_timezone` + (display ? '_display' : '')

	const timezone =
		(isEvent ? info?.[key] ?? undefined : info?.[`event_${key}`] ?? undefined) ||
		(display ? undefined : window[keys.DEFAULT_TIMEZONE])

	return timezone
}

export const validateAppTime = (inputDate, { timezone } = {}) => {
	const app_timezone = timezone || getAppTimezone()
	// When viewing an Event, the date/times must be in the event timezone.
	return moment(inputDate).tz(app_timezone)
}

// To feed the form input field
export const prepareTime = (input, { type = 'datetime' } = {}) => {
	if (type === 'date') {
		// For native date input
		const FORMAT = 'YYYY-MM-DD'
		return validateAppTime(input).format(FORMAT)
	}

	// For AntD datetime input
	return validateAppTime(input)
}

export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

// To pass along with POST/PATCH AJAX call
export const pullTime = (input, { type = 'datetime' } = {}) => {
	return moment(input).format(ISO_8601_FORMAT)
}

const formatDateIntl = (date, options) => {
	// Enables language-sensitive date and time formatting
	let str = new Intl.DateTimeFormat(undefined, options).format(date)
	str = str.replace(/\//g, '-').replace(/,/g, '')
	return str
}

// To view only
export const previewTime = (input, { type = 'datetime', showEmpty = false } = {}) => {
	if (!input) {
		if (showEmpty) return ''
		return NoDataUI
	}

	const momentDate = validateAppTime(input)
	const nativeDate = momentDate.toDate() // Convert moment object to native Date object

	const common = { timeZone: getAppTimezone(), year: 'numeric', month: '2-digit', day: '2-digit' }

	if (type === 'date') {
		// Output: 'DD-MM-YYYY' (As moment format)
		return formatDateIntl(nativeDate, { ...common })
	}

	// Output: 'DD-MM-YYYY HH:mm' (As moment format)
	return formatDateIntl(nativeDate, { ...common, hour: '2-digit', minute: '2-digit' })
}
